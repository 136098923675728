import { Publication } from '@/ts';
import * as process from 'process';
import { NAV_ONLY_PUBLICATIONS } from '@/constants';

const env = process && process.env;
const PUBLICATION = env.PUBLICATION || 'curiously';
const PUBLICATION_SOURCE = env.PUBLICATION_SOURCE || PUBLICATION;

export function checkPublications(articlePublications: Publication[], pub = PUBLICATION_SOURCE): boolean {
  return !!articlePublications.find((publication) => publication.name === pub);
}

export const isNavigatorOnly = (publication: string | undefined) =>
  publication && NAV_ONLY_PUBLICATIONS.includes(publication);

export { PUBLICATION_SOURCE };
export default PUBLICATION;
