export interface EventConstants {
  CLICK: string;
  COMPONENT_TYPE: string;
  COMPONENT_ACTION?: string;
  COMPONENT_CATEGORY: string;
  COMPONENT_LOCATION: string;
  COMPONENT_TEXT?: string;
  COMPONENT_ID?: string;
}

const EVENTS = {
  BREADCRUMBS: {
    CLICK: 'breadcrumbsClick',
    COMPONENT_TYPE: 'breadcrumbs',
    COMPONENT_ACTION: 'breadcrumbs click',
    COMPONENT_CATEGORY: 'navigation',
    COMPONENT_LOCATION: 'header',
  },
  BREAKING_NEWS: {
    ON_RENDER: {
      CLICK: 'breakingnewsRender',
      COMPONENT_TYPE: 'breakingnews',
      COMPONENT_ACTION: 'breakingnews displayed',
      COMPONENT_CATEGORY: 'recommendations',
      COMPONENT_LOCATION: 'header',
    },
    LINK_CLICK: {
      CLICK: 'breakingnewsClick',
      COMPONENT_TYPE: 'breakingnews',
      COMPONENT_ACTION: 'breakingnews link click',
      COMPONENT_CATEGORY: 'recommendations',
      COMPONENT_LOCATION: 'header',
    },
    CLOSED: {
      CLICK: 'breakingnewsClosed',
      COMPONENT_TYPE: 'breakingnews',
      COMPONENT_ACTION: 'breakingnews banner closed',
      COMPONENT_CATEGORY: 'recommendations',
      COMPONENT_LOCATION: 'header',
    },
  },
  BYLINE_AUTHOR_PROFILE: {
    CLICK: 'authorbylineClick',
    COMPONENT_TYPE: 'author byline',
    COMPONENT_ACTION: 'author byline click',
    COMPONENT_CATEGORY: 'navigation',
    COMPONENT_LOCATION: 'body',
  },
  CONTENT_TAGS: {
    CLICK: 'contenttagsClick',
    COMPONENT_TYPE: 'content tags',
    COMPONENT_ACTION: 'content tag click',
    COMPONENT_CATEGORY: 'navigation',
    COMPONENT_LOCATION: 'body',
  },
  FOOTER: {
    PRIMARY: {
      CLICK: 'primaryfooterClick',
      COMPONENT_TYPE: 'primary footer',
      COMPONENT_ACTION: 'primary footer link click',
      COMPONENT_CATEGORY: 'navigation',
      COMPONENT_LOCATION: 'footer',
    },
    SECONDARY: {
      CLICK: 'secondaryfooterClick',
      COMPONENT_TYPE: 'secondary footer',
      COMPONENT_ACTION: 'secondary footer link click',
      COMPONENT_CATEGORY: 'navigation',
      COMPONENT_LOCATION: 'footer',
    },
    TRUST_LOGO: {
      CLICK: 'primaryfooterClick',
      COMPONENT_TYPE: 'primary footer',
      COMPONENT_ACTION: 'trust project',
      COMPONENT_CATEGORY: 'navigation',
      COMPONENT_LOCATION: 'footer',
    },
    SOCIAL_FOLLOW: {
      CLICK: 'socialFollowClick',
      COMPONENT_TYPE: 'primary footer social follow',
      COMPONENT_ACTION: 'follow',
      COMPONENT_CATEGORY: 'navigation',
      COMPONENT_LOCATION: 'headline',
    },
  },
  GRID: {
    CLICK: 'gridClick',
    COMPONENT_TYPE: 'grid',
    COMPONENT_CATEGORY: 'embed',
    COMPONENT_LOCATION: 'body',
  },
  HEADER_NAV: {
    CURTAIN_LINK: {
      COMPONENT_ACTION: 'curtain link click',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    LOGO: {
      COMPONENT_ACTION: 'logo link click',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    NAV_LINK: {
      COMPONENT_ACTION: 'header link click',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    SOCIAL_LINK: {
      COMPONENT_ACTION: 'header social follow',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    SOCIAL_CURTAIN: {
      COMPONENT_ACTION: 'curtain social follow',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    BURGER_OPEN: {
      COMPONENT_ACTION: 'open burger menu',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    BURGER_CLOSE: {
      COMPONENT_ACTION: 'close burger menu',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    LINK_DROPDOWN_OPEN: {
      COMPONENT_ACTION: 'open burger menu item',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    LINK_DROPDOWN_CLOSE: {
      COMPONENT_ACTION: 'close burger menu item',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
    },
    MORE_OPEN: {
      COMPONENT_ACTION: 'open curtain',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
      COMPONENT_TEXT: 'More',
    },
    MORE_CLOSE: {
      COMPONENT_ACTION: 'close curtain',
      COMPONENT_CATEGORY: 'navigation - primary header',
      CLICK: 'primaryheaderClick',
      COMPONENT_TYPE: 'primary header',
      COMPONENT_LOCATION: 'header',
      COMPONENT_TEXT: 'Close',
    },
  },
  MAIN_CONTENT_SKIP: {
    CLICK: 'maincontentSkip',
    COMPONENT_TYPE: 'main content skip',
    COMPONENT_ACTION: 'skip to main content',
    COMPONENT_CATEGORY: 'navigation',
    COMPONENT_LOCATION: 'body',
    COMPONENT_TEXT: 'skip to main content',
    COMPONENT_ID: 'SkipToMain',
  },
  POLL: {
    CLICK: 'pollClick',
    COMPONENT_TYPE: 'poll',
    COMPONENT_ACTION: 'poll completed',
    COMPONENT_CATEGORY: 'embed',
    COMPONENT_LOCATION: 'body',
  },
  QUIZ: {
    STARTED: {
      CLICK: 'quizClick',
      COMPONENT_TYPE: 'quiz',
      COMPONENT_ACTION: 'quiz started',
      COMPONENT_CATEGORY: 'embed',
      COMPONENT_LOCATION: 'body',
    },
    COMPLETED: {
      CLICK: 'quizClick',
      COMPONENT_TYPE: 'quiz',
      COMPONENT_ACTION: 'quiz completed',
      COMPONENT_CATEGORY: 'embed',
      COMPONENT_LOCATION: 'body',
    },
    REPLAY: {
      CLICK: 'quizClick',
      COMPONENT_TYPE: 'quiz',
      COMPONENT_ACTION: 'replay quiz',
      COMPONENT_CATEGORY: 'embed',
      COMPONENT_LOCATION: 'body',
    },
  },
  RECOMMENDED: {
    CLICK: 'recommendedClick',
    COMPONENT_TYPE: 'recommendations',
    COMPONENT_ACTION: 'right hand rail click',
    COMPONENT_CATEGORY: 'recommendations',
    COMPONENT_LOCATION: 'right hand rail',
  },
  SECONDARY_HEADER_NAVIGATION: {
    CLICK: 'secondaryheaderClick',
    COMPONENT_TYPE: 'secondary header',
    COMPONENT_ACTION: 'secondary header link click',
    COMPONENT_CATEGORY: 'navigation',
    COMPONENT_LOCATION: 'header',
  },
  SOCIAL_FOLLOW: {
    CLICK: 'socialFollowClick',
    COMPONENT_TYPE: 'social follow bar',
    COMPONENT_ACTION: 'follow',
    COMPONENT_CATEGORY: 'navigation',
    COMPONENT_LOCATION: 'headline',
  },
  SOCIAL_SHARE: {
    CLICK: 'sharebarClick',
    COMPONENT_TYPE: 'share bar',
    COMPONENT_ACTION: 'share bar',
    COMPONENT_CATEGORY: 'navigation',
    COMPONENT_LOCATION: 'headline',
  },
  WEB_ALERTS: {
    REACH_PROMPT_DISPLAYED: {
      CLICK: 'reachPromptDisplayed',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Reach Prompt Displayed',
    },
    REACH_PROMPT_ACCEPTED: {
      CLICK: 'reachPromptAccepted',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Reach Prompt Accepted',
    },
    REACH_PROMPT_DECLINED: {
      CLICK: 'reachPromptDeclined',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Reach Prompt Declined',
    },
    BROWSER_PROMPT_DISPLAYED: {
      CLICK: 'browserPromptDisplayed',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Browser Prompt Displayed',
    },
    BROWSER_PROMPT_ALLOWED: {
      CLICK: 'browserPromptAllowed',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Browser Prompt Allowed',
    },
    BROWSER_PROMPT_BLOCKED: {
      CLICK: 'browserPromptBlocked',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Browser Prompt Blocked',
    },
    WEB_ALERT_SUBSCRIBE_SUCCESS: {
      CLICK: 'webAlertAirshipSubscribeSuccess',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Web Alert Airship Subscribe Success',
    },
    WEB_ALERT_SUBSCRIBE_SUCCESS_OS: {
      CLICK: 'webAlertOneSignalSubscribeSuccess',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Web Alert OneSignal Subscribe Success',
    },
    WEB_ALERT_MIGRATE_SUCCESS_OS: {
      CLICK: 'webAlertOneSignalMigrateSuccess',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Web Alert OneSignal Migrate Success',
    },
    WEB_ALERT_SUBSCRIBE_FAIL: {
      CLICK: 'webAlertAirshipSubscribeFail',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Web Alert Airship Subscribe Fail',
    },
    WEB_ALERT_SUBSCRIBE_FAIL_OS: {
      CLICK: 'webAlertOneSignalSubscribeFail',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Web Alert OneSignal Subscribe Fail',
    },
    WEB_ALERT_NOTIFICATION_CLICKED: {
      CLICK: 'pushNotificationClicked',
      COMPONENT_TYPE: 'Web Alerts',
      COMPONENT_CATEGORY: 'Engagement (PIE)',
      COMPONENT_LOCATION: '',
      COMPONENT_ACTION: 'Push Notifcation Clicked',
    },
  },
  YOUTUBE_VIDEO: {
    PLAY: {
      CLICK: 'youtubePlay',
      COMPONENT_TYPE: 'youtube',
      COMPONENT_ACTION: 'youtube video play',
      COMPONENT_CATEGORY: 'embed',
      COMPONENT_LOCATION: 'body',
    },
    COMPLETED: {
      CLICK: 'youtubeCompleted',
      COMPONENT_TYPE: 'youtube',
      COMPONENT_ACTION: 'youtube video completed',
      COMPONENT_CATEGORY: 'embed',
      COMPONENT_LOCATION: 'body',
    },
  },
  PDF_EMBED: {
    CLICK: 'pdfClick',
    COMPONENT_TYPE: 'pdf',
    COMPONENT_CATEGORY: 'embed',
    COMPONENT_LOCATION: 'body',
  },
  READ_NEXT: {
    CLICK: 'readNextClick',
    COMPONENT_TYPE: 'recommendations',
    COMPONENT_ACTION: 'read next click',
    COMPONENT_CATEGORY: 'recommendations',
    COMPONENT_LOCATION: 'body',
    COMPONENT_TEXT: '',
    COMPONENT_ID: '',
  },
};

const buildMap =
  (map: Map<string, any>) =>
  (obj: Object, primaryKey: string = '') => {
    Object.entries(obj).forEach(([key, value]) => {
      const mapKey = primaryKey === '' ? key : `${primaryKey}:${key}`;
      if (!Object.keys(value as Object).includes('CLICK')) {
        buildMap(map)(value as Object, mapKey);
      } else {
        map.set(mapKey, value);
      }
    });

    return map;
  };

export const EVENT_MAP = ((events: any) => {
  const map = new Map<string, any>();

  return buildMap(map)(events);
})(EVENTS);

export default EVENTS;
