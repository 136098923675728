import log from '@/helpers/utils/log';
import getDirFileName from '../utils/dirPath';

const gtmPush = (data: any) => {
  if (!window || !window.dataLayer) {
    const fileDirectory = getDirFileName(import.meta.url, gtmPush.name);
    log().debug(`${fileDirectory} Unable to push to GTM dataLayer`);
    return;
  }

  window.dataLayer.push(data);
};

export default gtmPush;
